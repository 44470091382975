import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CopyrightFooterContainer, LegalItems } from './Footer.css';
import LocaleContext from '../../context/LocaleProvider';
import Text from '../Text/Text';
import { Desktop } from '../Media/Media';
import LocalizedLink from '../LocalizedLink';

const CopyrightFooter = () => {
  const data = useStaticQuery(graphql`
    query CopyrightFooterQuery {
      copyrightFooter: allPrismicCopyrightFooter {
        edges {
          node {
            lang
            data {
              legal_links {
                legal_link {
                  uid
                }
                legal_link_label {
                  text
                }
              }
              copyright_company {
                text
              }
              hide_footer
            }
          }
        }
      }
    }
  `);

  const lang = React.useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];
  const date = new Date();

  const legalFooter = data.copyrightFooter.edges
    .filter((edge) => edge.node.lang === i18n.locale)
    .map((r) => r.node.data);

  return (
    <CopyrightFooterContainer>
      <LegalItems className='legalItems'>
        {!legalFooter[0].hide_footer &&
          legalFooter[0].legal_links &&
          legalFooter[0].legal_links.map((legal_label, index) => {
            return (
              <LocalizedLink
                className='LegalFooter__Content-Container'
                key={`CopyrightFooter - ${index}`}
                to={`/${legal_label.legal_link.uid}`}
              >
                <Text customClassName='LegalFooter__Content-Text'>
                  {legal_label.legal_link_label.text}
                </Text>
                <Desktop>
                  <p
                    style={{
                      marginBottom: '2px',
                      padding: '0 3px',
                      textDecoration: 'none',
                      fontWeight: '100',
                    }}
                  >
                    {index === legalFooter[0].legal_links.length - 1
                      ? ''
                      : ' — '}
                  </p>
                </Desktop>
              </LocalizedLink>
            );
          })}
      </LegalItems>
      {legalFooter[0].copyright_company ? (
        <Text customClassName='LegalFooter__Copyright-text'>
          {`${legalFooter[0].copyright_company.text} ${date.getFullYear()}`}
          {' — '}
          {i18n.locale === 'fr-ca'
            ? 'Design et développement par'
            : 'Designed and Built by'}{' '}
          <a
            href='https://rap.agency/'
            target='_blank'
            rel='noreferrer'
            style={{ lineHeight: 'normal', textDecoration: 'underline' }}
          >
            Rap
          </a>{' '}
          ×{' '}
          <a
            href='https://www.field-office.ca'
            target='_blank'
            rel='noreferrer'
            style={{ lineHeight: 'normal', textDecoration: 'underline' }}
          >
            Field Office
          </a>
        </Text>
      ) : (
        ''
      )}
    </CopyrightFooterContainer>
  );
};

export default CopyrightFooter;
