import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SocialMedia from '../SocialMedia/SocialMedia';
import Img from 'gatsby-image';
import { Desktop, Mobile } from '../Media/Media';
import { FooterContainerMain, FooterWrapper } from './Footer.css';
import LocaleContext from '../../context/LocaleProvider';
import Text from '../Text/Text';
import CopyrightFooter from './CopyrightFooter';
import { theme } from '../../styles';
import LocalizedLink from '../LocalizedLink';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      footer: allPrismicFooter {
        edges {
          node {
            lang
            data {
              logo {
                url
              }
              mail_to {
                text
              }
              contact_email {
                text
              }
              contact_tel {
                text
              }
              contact_address {
                text
              }
              pdf_label {
                text
              }
              pdf_link {
                url
              }
            }
          }
        }
      }
    }
  `);

  const lang = React.useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  const footer = data.footer.edges
    .filter((edge) => edge.node.lang === i18n.locale)
    .map((r) => r.node.data);

  return (
    <FooterWrapper>
      <hr
        className='Footer__Section-BreakLine'
        style={{
          borderTop: '1px solid black',
          marginBottom: theme.scale('50px'),
        }}
      />
      <FooterContainerMain>
        <div className='Footer__Container-Logo'>
          <LocalizedLink to="/">
            <img className='Header__Logo' src={footer[0].logo.url} />
          </LocalizedLink>
        </div>
        <div className='Footer__Content'>
          <div className='Footer__Contact-container'>
            <Desktop>
              <div className='Footer__Contact'>
                {footer[0].contact_email ? (
                  <>
                    <Text customClassName='Footer__text'>
                      <a
                        className='Footer__MailTo-button'
                        href={`mailto:${footer[0].mail_to.text}`}
                      >
                        {footer[0].contact_email.text}
                      </a>
                    </Text>
                    <Text customClassName='Footer__text'>
                      {footer[0].contact_tel.text}
                    </Text>
                  </>
                ) : (
                  ''
                )}
              </div>
            </Desktop>
            <Mobile>
              <div className='Footer__Contact'>
                {footer[0].contact_email ? (
                  <Text customClassName='Footer__text'>
                    <a
                      className='Footer__MailTo-button'
                      href={`mailto:${footer[0].mail_to.text}`}
                    >
                      {footer[0].contact_email.text}
                    </a>
                  </Text>
                ) : (
                  ''
                )}
                {footer[0].contact_tel ? (
                  <Text customClassName='Footer__text'>
                    {footer[0].contact_tel.text}
                  </Text>
                ) : (
                  ''
                )}
              </div>
            </Mobile>
            <div className='Footer__Contact'>
              {footer[0].contact_address ? (
                <Text customClassName='Footer__text'>
                  {footer[0].contact_address.text}
                </Text>
              ) : (
                ''
              )}
            </div>

            <SocialMedia customClassName='Footer__SocialMedia' />
          </div>
          {footer[0].pdf_link && footer[0].pdf_label ? (
            <div className='Footer__Report-container'>
              <a href={footer[0].pdf_link.url} target='_blank' rel='noopener'>
                {footer[0].pdf_label.text}
              </a>
            </div>
          ) : (
            ''
          )}
        </div>
        <CopyrightFooter />
      </FooterContainerMain>
    </FooterWrapper>
  );
};

export default Footer;
